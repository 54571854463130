import { filterAssetsByType, filterAssetsByParent } from './index';

export const getSubTypesForPlaceholder = (currentPh, aphs) => {
  const subTypes = aphs
    .filter(placeholder => {
      return (
        placeholder.parentAssetPlaceholderId ===
          currentPh.parentAssetPlaceholderId &&
        placeholder.assetType?.id === currentPh.assetType?.id &&
        placeholder.assetSubType
      );
    })
    .map(ph => ph.assetSubType);

  return subTypes;
};

export const processAssetPlaceholders = (
  phDict,
  allAssetsArr,
  aphs,
  currentPh
) => {
  const filteredByType = filterAssetsByType(allAssetsArr, currentPh);
  const filteredByParent = filterAssetsByParent(
    phDict,
    filteredByType,
    currentPh
  );

  const subTypes = getSubTypesForPlaceholder(currentPh, aphs);

  // eslint-disable-next-line no-param-reassign
  phDict[currentPh.id].possibleAssets = filteredByParent.map(instance => {
    return {
      ...instance,
      typeIndex: { ...currentPh, possibleAssetSubTypes: subTypes },
    };
  });

  const aphChildren = aphs.filter(
    a => a.parentAssetPlaceholderId === currentPh.id
  );
  aphChildren.forEach(child =>
    processAssetPlaceholders(phDict, allAssetsArr, aphs, child)
  );
};
