/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import { Grid, Typography, Box } from '@mui/material';
import PT from 'prop-types';
import { LoadingButton } from '@mui/lab';
import { TextField } from 'components/pickers/final-form-fields';
import { isEmpty } from 'lodash';
import { emojiValidation } from 'utils';
import Alert from 'components/alerts';
import { useEffect, useState } from 'react';
import useCreateDataSet from './hooks';

export default function CreateDataSet({ values, pushMutator }) {
  const {
    onClick,
    isAssetsLoading,
    isDataSetLoading,
    error,
    newDataSetAssets,
    newDataSet,
    dataSetName,
    setDataSetName,
    setError,
  } = useCreateDataSet(values, pushMutator);
  const [alertOpen, setAlertOpen] = useState(false);
  const [retryCounter, setRetryCounter] = useState(0);

  useEffect(() => {
    if (!isEmpty(error)) {
      setAlertOpen(true);
    }
  }, [error]);

  const isDataSetNameDuplicated =
    error?.details?.includes('already exists') &&
    error?.details?.includes('name');

  const assetsDoNotExist =
    error?.details?.includes('Asset(s)') &&
    error?.details?.includes('do not exist');

  return (
    <Grid
      container
      display="flex"
      direction="column"
      alignContent="left"
      spacing={2}
    >
      <Grid item container direction="row" aria-label="confirmDataSetInfo">
        <Typography color="GrayText">
          This will create and save the data set. The process will create new
          assets if required and add the relevant parameters to both new and
          existing assets.
        </Typography>
      </Grid>
      <Grid item pb={2}>
        {!isEmpty(error) && alertOpen && (
          <Box sx={{ padding: theme => theme.spacing(3) }}>
            {!isEmpty(newDataSetAssets) && retryCounter === 0 && (
              <Alert
                ariaLabel="create-data-asset-successful"
                title="Successfully added new assets."
                severity="success"
                variant="outlined"
                boxStyle={{
                  marginBottom: theme => theme.spacing(1),
                  paddingBottom: theme => theme.spacing(0),
                }}
              />
            )}
            <Alert
              ariaLabel="create-data-set-error"
              title={
                isDataSetNameDuplicated
                  ? 'Data set with requested name already exists'
                  : assetsDoNotExist
                  ? 'One or more assets selected do not exist in the project'
                  : error.msg
              }
              text={
                isDataSetNameDuplicated
                  ? 'Close this error message, change the data set name and create it again.'
                  : assetsDoNotExist
                  ? 'One or more assets selected could have been deleted by another user in the meantime. Close this wizard, add/restore any missing assets and try to create the data set again.'
                  : error?.details
              }
              severity="error"
              variant="outlined"
              onClose={() => {
                setAlertOpen(false);
                setError({});
                setRetryCounter(curr => curr + 1);
              }}
            />
          </Box>
        )}
        {isEmpty(error) &&
          !isAssetsLoading &&
          !isDataSetLoading &&
          !isEmpty(newDataSet) && (
            <Alert
              ariaLabel="create-data-set-successful"
              severity="success"
              variant="outlined"
              boxStyle={{
                alignItems: 'center',
              }}
              text={
                <>
                  {!isEmpty(newDataSetAssets) && retryCounter === 0 && (
                    <Typography>Successfully added new assets.</Typography>
                  )}
                  <Typography>
                    Successfully created data set. Press `Finish` to to close
                    the wizard.
                  </Typography>
                </>
              }
            />
          )}
        {isEmpty(newDataSet) && !alertOpen && (
          <Grid
            container
            direction="row"
            spacing={1}
            justifyContent="center"
            sx={{ padding: theme => theme.spacing(3) }}
          >
            <Grid item xs={9} sm={9}>
              <TextField
                aria-label="addDataSetName"
                helperText={`Suggested Name: ${values.suggestedName}`}
                fullWidth
                label="Data Set Name"
                name="dataSetName"
                type="text"
                required
                variant="standard"
                size="small"
                onChange={e => {
                  setDataSetName(e.target.value);
                }}
                value={dataSetName}
              />
            </Grid>
            <Grid item xs={9} sm={9}>
              <LoadingButton
                aria-label="downloadButton"
                loading={isAssetsLoading || isDataSetLoading}
                disabled={
                  !dataSetName ||
                  emojiValidation(dataSetName) ||
                  !isEmpty(newDataSet)
                }
                variant="contained"
                sx={{ width: '100%', marginTop: 2 }}
                onClick={onClick}
              >
                Create data set
              </LoadingButton>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

CreateDataSet.propTypes = {
  values: PT.shape({
    dataSetName: PT.string,
    suggestedName: PT.string,
    parameterTypes: PT.arrayOf(PT.shape({})),
    parameterSet: PT.arrayOf(PT.shape({})),
  }).isRequired,
  pushMutator: PT.func.isRequired,
};
