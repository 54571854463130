/* eslint-disable max-depth */
/* eslint-disable import/prefer-default-export */

export const formatAssetNavigationDataSetWiz = (
  allAssets,
  parent,
  parentType,
  topAsset,
  parentPlaceholderId
) => {
  const assetTypes = [];
  const response = [];

  if (allAssets.length) {
    allAssets.reduce((acc, asset) => {
      const parentAssetPlaceholderId = asset?.isTypeOnly
        ? asset.parentAssetPlaceholderId
        : asset?.typeIndex?.parentAssetPlaceholderId;

      if (
        asset.parent === parent ||
        (topAsset && asset.assetType?.id === parentType) ||
        asset?.parent === parentPlaceholderId
      ) {
        const assetTypeId = asset.assetType?.id;

        const existingAssetTypeIndex = assetTypes.indexOf(assetTypeId);

        if (existingAssetTypeIndex === -1) {
          assetTypes.push(asset.assetType?.id);
          response.push({
            id: `${asset.assetType?.id}_${parent}`,
            name: asset.assetType?.name,
            assetType: {
              ...asset?.assetType,
              parent: asset?.assetType?.parent || parentType,
            },
            parent,
            assetPlaceholderId: asset.isTypeOnly
              ? asset.id
              : asset?.typeIndex?.id,
            parentAssetPlaceholderId,
            assetSubType: asset.isTypeOnly
              ? asset.assetSubType
              : asset?.typeIndex?.assetSubType,
            isAssetInstance: false,
            children: [],
            isTypeOnly: true,
            possibleAssetSubTypes: asset.isTypeOnly
              ? asset.possibleAssetSubTypes
              : asset?.typeIndex?.possibleAssetSubTypes,
            defaultName: asset.isTypeOnly
              ? asset.defaultName
              : asset?.typeIndex?.defaultName,
            assetTypeMaxCount: asset.isTypeOnly
              ? asset.maxCount
              : asset?.typeIndex?.maxCount,
          });
        }
        const index = assetTypes.indexOf(asset.assetType?.id);
        if (!asset.isTypeOnly) {
          let multipleSubtypes = false;
          if (asset?.typeIndex?.possibleAssetSubTypes?.length > 1) {
            multipleSubtypes = true;
          }
          response[index].children.push({
            id: asset?.id,
            parent: asset.parent,
            name: asset?.name,
            isAssetInstance: true,
            assetType: { ...asset?.assetType },
            assetSubType: asset?.assetSubType
              ? { ...asset.assetSubType }
              : null,
            edited: asset?.edited,
            new: asset?.new,
            assetTypeMaxCount: asset?.typeIndex?.maxCount,
            assetPlaceholderId: asset?.typeIndex?.id,
            parentAssetPlaceholderId:
              asset?.typeIndex?.parentAssetPlaceholderId,
            multipleSubtypes,
            possibleAssetSubTypes: asset?.typeIndex?.possibleAssetSubTypes,
            defaultName: asset?.typeIndex?.defaultName,
            children: formatAssetNavigationDataSetWiz(
              allAssets,
              asset?.id,
              asset?.assetType?.id,
              null,
              asset?.typeIndex?.id
            ),
          });
        }
      }
      return acc;
    }, []);
  }
  return response;
};
