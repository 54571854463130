import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';

export default templateAssets => {
  const [openSummary, setOpenSummary] = useState(true);
  const [expandedNodes, setExpandedNodes] = useState([]);
  const handleOpen = () => setOpenSummary(!openSummary);

  useEffect(() => {
    if (!isEmpty(templateAssets)) {
      const assetNodeList = templateAssets.map(asset => asset.id);
      setExpandedNodes([...assetNodeList]);
    }
  }, [templateAssets]);

  return {
    openSummary,
    handleOpen,
    expandedNodes,
    setExpandedNodes,
  };
};
