/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-nested-ternary */
import { Grid, Skeleton, CircularProgress } from '@mui/material';
import PT from 'prop-types';
import { List as ListIcon } from '@mui/icons-material';
import { TreeView } from '@mui/lab';
import { isEmpty } from 'lodash';
import EmptyState from 'components/empty-state';
import { Suspense } from 'react';
import useAssets from './hooks/use-assets';
import TreeItems from './tree-items';

const AssetTree = ({
  assetNodes,
  loading,
  updateSelection,
  onAddAssetClick,
  onEditClick,
  onClearClick,
  onDoneClick,
  textFieldError,
  onRemoveAssetClick,
  values,
  assetsLoading,
  change,
}) => {
  const { onNodeSelect, onNodeToggle, selectedNode } = useAssets();
  const hierarchy = assetNodes;

  return (
    <Grid
      aria-label="asset-tree-navigation"
      sx={{
        overflow: 'true',
        height: '100%',
      }}
    >
      <TreeView
        onNodeToggle={onNodeToggle}
        onNodeSelect={onNodeSelect}
        expanded={values?.expanded || []}
      >
        {assetsLoading ? (
          <CircularProgress
            aria-label="loading"
            size="20%"
            sx={{ marginTop: '15%', marginLeft: '35%' }}
          />
        ) : !loading && isEmpty(hierarchy) ? (
          <EmptyState
            Icon={ListIcon}
            title="No Assets"
            subtitle="Try adding a new asset using the button above"
          />
        ) : (
          <Suspense
            fallback={
              <Skeleton aria-label="loading" width="100%" height="30px" />
            }
          >
            {hierarchy.map(node => {
              return (
                <TreeItems
                  node={node}
                  key={node.id}
                  updateSelection={updateSelection}
                  selectedAssets={values.selectedAssets}
                  selectedNode={selectedNode}
                  onAddAssetClick={onAddAssetClick}
                  onEditClick={onEditClick}
                  onClearClick={onClearClick}
                  onDoneClick={onDoneClick}
                  textFieldError={textFieldError}
                  onRemoveAssetClick={onRemoveAssetClick}
                  values={values}
                  change={change}
                />
              );
            })}
          </Suspense>
        )}
      </TreeView>
    </Grid>
  );
};
AssetTree.propTypes = {
  loading: PT.bool,
  assetNodes: PT.arrayOf(
    PT.shape({
      id: PT.string,
      name: PT.string,
      isAssetInstance: PT.bool,
      children: PT.array,
    })
  ),
  onAddAssetClick: PT.func,
  onEditClick: PT.func,
  updateSelection: PT.func,
  onClearClick: PT.func,
  onDoneClick: PT.func,
  textFieldError: PT.string,
  onRemoveAssetClick: PT.func,
  values: PT.shape({
    selectedAssets: PT.arrayOf(PT.shape({})),
    expanded: PT.arrayOf(PT.string),
  }),
  assetsLoading: PT.bool,
  change: PT.func,
};

AssetTree.defaultProps = {
  loading: false,
  assetNodes: [],
  onAddAssetClick: () => {},
  onEditClick: () => {},
  onClearClick: () => {},
  onDoneClick: () => {},
  updateSelection: () => {},
  textFieldError: '',
  onRemoveAssetClick: () => {},
  values: {},
  assetsLoading: false,
  change: () => {},
};
export default AssetTree;
